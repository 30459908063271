$green: #28a745;
$blue: #3639ed;
$orange: #ffa00e;
$red: #ef1f1c;
$yellow: #ffe300;
$darker-yellow: #ddc500;
$light-green: #c1eeb7;
$light-green2: #aed9ad;

$bg-header: #ddd;
$bg-nav: #fff;
$bg-body: #eee;
$border-color: #aaa;
$footer-height: 50px;

$offset: 30px;
$wca-logo-size: 32px;

