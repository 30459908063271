#competition-results-area {
  .nav-item {
    background-color: transparent;
  }
  .scrambles-card {
    .card-header {
      cursor: pointer;
    }
  }
  .list-group-item {
    &.gold {
      background-color: #ddc583;
    }
    &.silver {
      background-color: #ccc;
    }
    &.bronze {
      background-color: #c98f6c;
    }
  }
}
