@import '~bootstrap/scss/bootstrap';
$fa-font-path: '~fork-awesome/fonts';
@import '~fork-awesome/css/fork-awesome.min.css';

@import '~flag-icon-css/css/flag-icon.min.css';
@import 'easymde/dist/easymde.min.css';
@import 'fullcalendar/dist/fullcalendar.min.css';

@import 'selectize/dist/css/selectize.bootstrap3.css';
@import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.css';

.selectize-input {
  &.focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  .is-invalid > & {
    border-color: #dc3545;
    &.focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
  }
}

@import '../src/variables';
@import '../src/navigation';
@import '../src/page-nav';
@import '../src/homepage';
@import '../src/footer';
@import '../src/sessions';
@import '../src/hardwares';
@import '../src/external_resources';
@import '../src/cubing-icons';
@import '../src/results';

html {
  min-height: 100%;
}

body {
  background-color: $bg-body;
}

blockquote {
  @extend .blockquote;
}


#main-container {
  padding-top: 2*$offset;
  .container {
    flex-direction: column;
  }
}

.table-bordered {
  th, td {
    border-color: #212529;
  }
}

.custom-checkbox {
  padding-left: 15px;
  .custom-control-label {
    &::before {
      border: 1px solid #aaa;
    }
    margin-left: 25px;
  }
}

.user-edit-form {
  .custom-checkbox {
    padding-left: 0;
    .custom-control-label {
      &::before, &::after {
        right: 5px;
        left: auto;
      }
      @extend .col-md-6;
      @extend .col-lg-5;
      margin-left: 0;
    }
  }
}

.post-excerpt {
  p {
    margin-bottom: 0;
  }
}

.tag {
  &:not(.tag-action):hover {
    cursor: default;
  }
  &.tag-action {
    &:hover {
      opacity: 0.5;
    }
  }
  .tag-index > & {
    font-size: 1.25rem;
  }
  .afs-post &, .regular-post &, .featured-post & {
    font-size: 1rem;
  }
}

.color-green {
  color: $green;
}

.color-blue {
  color: $blue;
}

.color-orange {
  color: $orange;
}

.datepicker {
  table {
    margin: 4px;
  }
}

input[type=color].form-control {
    height: 2.25rem;
}

.remove-padding .card-body {
  padding: 0;
  table {
    margin-bottom: 0;
  }
}

.bg-lightgreen {
  background-color: $light-green;
}

tr:nth-child(even) {
  th.bg-lightgreen {
    background-color: $light-green;
  }
}

tr:nth-child(odd) {
  th.bg-lightgreen {
    background-color: $light-green2;
  }
}

#logo-history {
  text-align: center;

  td {
    vertical-align: middle;
  }

  img {
    width: 100%;
  }

  .noletters {
    img {
      max-width: 150px;
    }
  }

  .letters {
    img {
      max-width: 350px;
    }
  }
}
