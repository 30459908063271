.navbar {
  padding-bottom: 0;
  background-color: $bg-header;
  border-bottom: 2px solid $border-color;
  // The whole header
  .container {
    flex-direction: column;
    .navbar-brand {
      z-index: 3;
      height: 90px;
      width: 100%;
      text-align: center;
      a {
        &:hover, &:active {
          text-decoration: none;
        }
      }
      .brand-logo {
        @extend .ml-5;
        float: left;
        width: 100px;
        filter: grayscale(20%) drop-shadow(0px 0px 2px black);
      }
      .brand-header {
        margin-top: 10px;
        .brand-name {
          .blue {
            color: $blue;
          }
          .white {
            color: #fff;
            text-shadow: black 0 0px 2px;
          }
          .red {
            color: $red;
          }
          font-size: 30px;
        }
        .brand-social {
          a {
            color: rgba(0, 0, 0, 0.5);
            margin-right: 5px;
            &:hover {
              color: rgba(0, 0, 0, 0.9);
            }
          }
        }
      }
    }
    #navbar-afs {
      background-color: $bg-nav;
      position: relative;
      margin-bottom: -$offset;
      border: 2px solid $border-color;
      @include media-breakpoint-up(md) {
        margin-right: 50px;
      }
      @include media-breakpoint-up(sm) {
        margin-left: 180px;
      }
      .dropdown:hover > .dropdown-menu {
        display: block;
      }
      .dropdown-menu {
        margin-top: inherit;
      }
      ul {
        min-height: 55px;
        padding-bottom: 10px;
        .avatar-thumbnail {
          width: 45px;
          height: 45px;
          margin-top: -3px;
          margin-bottom: -8px;
          display: inline-block;
          vertical-align: middle;
          background-size: cover;
        }
        .nav-item > a, .nav-item > .signin-elem > a {
          @extend .mx-1;
          &:not(.no-border) {
            border-bottom: 4px solid;
          }
          margin-bottom: -4px;
          &.nav-association {
            &:hover, &:active, &.active, i {
              color: $blue;
            }
            border-color: $blue;
          }
          &.nav-home {
            &:hover, &:active, &.active, i {
              color: $red;
            }
            border-color: $red;
          }
          &.nav-speedcubing {
            &:hover, &:active, &.active, i {
              color: $orange;
            }
            border-color: $orange;
          }
          &.nav-contact {
            &:hover, &:active, &.active, i {
              color: $green;
            }
            border-color: $green;
          }
          &.nav-signin {
            &::before {
              content: '';
              background-image: url("../images/wca_logo.svg");
              background-position: center;
              background-size: cover;
              position: absolute;

              width: $wca-logo-size;
              height: $wca-logo-size;
              top: 5px;
              left: 5px;
            }
            &:hover, &:active, i {
              color: $darker-yellow;
            }
            border-color: $darker-yellow;

            padding-left: $wca-logo-size + 10px;
            overflow: hidden;
            position: relative;
          }
        }
        .nav-i18n {
          padding-top: 12px;
        }
      }
    }
  }
}

.nav {
  &.sticky-nav {
    position: sticky;
    z-index: 10;
    top: 0px;
    background-color: $bg-body;
  }
}
